import testimonialData from './data'
import TestimonialCard from './components/TestimonialCard'

function Testimonial({ className }) {
  return (
    <div
      className={`grid lg:grid-cols-3 gap-4 ${className}`}
    >
      {
        testimonialData.map(({
          id,
          img,
          name,
          schoolYear,
          testimony,
        }) => (
          <TestimonialCard
            key={id}
            img={img}
            name={name}
            schoolYear={schoolYear}
            testimony={testimony}
          />
        ))
      }
    </div>
  )
}

export default Testimonial
