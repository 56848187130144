export default function scrollCarousel(targetImageNumber, setIndex, carouselElement) {
  const carouselWidth = carouselElement.clientWidth

  const targetImage = targetImageNumber

  const targetXPixel = (carouselWidth * targetImage) + 1

  carouselElement.scrollTo(targetXPixel, 0)
  setIndex(targetImageNumber)
}
