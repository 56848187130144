function Divider() {
  return (
    <div
      className={'flex justify-center items-end flex-1'}
    >
      <div
        className={'h-[4px] w-[40%] mb-2 rounded bg-black-brand'}
      />
    </div>
  )
}

export default Divider
