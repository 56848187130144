function Section({ className, children }) {
  return (
    <div
      className={className}
    >
      <div
        className={'container py-4 md:!py-16'}
      >
        {children}
      </div>
    </div>
  )
}

export default Section
