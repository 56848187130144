function Card({ className, children }) {
  return (
    <div
      className={`card shadow-xl p-4 ${className}`}
    >
      {children}
    </div>
  )
}

export default Card
