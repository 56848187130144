import Logo from 'assets/ags-logo-512x512.png'

function Avatar({ size = 10 }) {
  return (
    <div
      className={`w-${size} rounded-full bg-neutral-200`}
    >
      {
        <img
          src={Logo}
          alt={'AGS Avatar'}
        />
      }
    </div>
  )
}

export default Avatar
