function MenuSection({ children, title, className }) {
  return (
    <div
      className={`${className}`}
    >
      <h3
        className={'font-semibold ml-2 text-lg'}
      >
        {title}
      </h3>

      <div
        className={'mt-2 card border-0 font-body'}
      >
        {children}
      </div>
    </div>
  )
}

export default MenuSection
