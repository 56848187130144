import {
  createBrowserRouter,
} from 'react-router-dom'
import MainLayout from 'components/layouts/MainLayout'
import Index from 'pages'
import Menu from 'pages/menu'
import Error from 'pages/Error'

const router = createBrowserRouter([
  {
    path: '/',
    element: <MainLayout />,
    children:
    [
      {
        index: true,
        element: <Index />,
      },
      {
        path: 'menu',
        element: <Menu />,
      },
      {
        path: '*',
        element: <Error />,
      },
    ],
  },
])

export default router
