import { useContext } from 'react'
import CarouselElementContext from '../context/CarouselElementContext'
import scrollCarousel from '../utils/scrollCarousel'

function CarouselArrow({ scrollToIndex, setIndex, children }) {
  const carouselElement = useContext(CarouselElementContext)

  if (!carouselElement) return null

  return (
    <button
      type={'button'}
      className={'text-black-brand bg-transparent'}
      onClick={() => scrollCarousel(scrollToIndex, setIndex, carouselElement)}
    >
      {children}
    </button>
  )
}

export default CarouselArrow
