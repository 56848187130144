import MenuDrawer from 'features/MenuDrawer'

function Menu() {
  return (
    <div
      className={'container py-10'}
    >
      <MenuDrawer />
    </div>
  )
}

export default Menu
