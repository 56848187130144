/* eslint-disable jsx-a11y/alt-text */
import CarouselItem1 from 'assets/images/img_bg_11.png'
import CarouselItem2 from 'assets/images/img_bg_22.png'
import CarouselItem3 from 'assets/images/img_bg_33.png'
import CarouselItem4 from 'assets/images/img_bg_44.png'
import { useState, useEffect, useRef } from 'react'
import CarouselItem from './components/CarouselItem'
import CarouselIndicator from './components/CarouselIndicator'
import CarouselElementContext from './context/CarouselElementContext'
import CarouselArrow from './components/CarouselArrow'
import CarouselArrowContainer from './components/CarouselArrowContainer'

function Carousel() {
  const [
    carouselElement,
    setCarouselElement,
  ] = useState(null)

  const carouselList = [
    {
      isImage: false,
      id: 'landing1',
      heading: 'Join Us Now!',
      subheading: 'Meeting Times',
      paragraph: 'Day Meeting - Thursday 11:15 A.M\nNight Meeting - Thursday 6:00 P.M',
    },
    {
      id: 'poster1',
      src: CarouselItem1,
      alt: 'Welcome back Poster',
    },
    {
      id: 'poster2',
      src: CarouselItem2,
      alt: 'General Meeting Poster',
    },
    {
      id: 'poster3',
      src: CarouselItem3,
      alt: 'Volunteering Poster',
    },
    {
      id: 'poster4',
      src: CarouselItem4,
      alt: 'Social Poster',
    },
  ]

  const [
    index,
    setIndex,
  ] = useState(0)

  const leftIndex = index - 1 >= 0 ? index - 1 : carouselList.length - 1
  const rightIndex = index + 1 < carouselList.length ? index + 1 : 0
  const carouselElementRef = useRef(null)

  useEffect(() => {
    if (carouselElementRef.current) {
      setCarouselElement(carouselElementRef.current)
    }
  }, [
    carouselElementRef,
  ])

  return (
    <CarouselElementContext.Provider
      value={carouselElement}
    >
      <section
        className={'relative'}
      >
        <div
          className={'relative h-auto'}
        >
          <div
            className={'carousel w-full'}
            ref={carouselElementRef}
          >
            {carouselList.map(({
              id, src, alt, heading, subheading, paragraph, isImage,
            }) => (
              <CarouselItem
                id={id}
                src={src}
                alt={alt}
                heading={heading}
                subheading={subheading}
                paragraph={paragraph}
                isImage={isImage}
                key={id}
              />
            ))}
          </div>

          <CarouselIndicator
            currentIndex={index}
            carouselItemList={carouselList}
            setIndex={setIndex}
          />
        </div>

        <CarouselArrowContainer>
          <CarouselArrow
            scrollToIndex={leftIndex}
            setIndex={setIndex}
          >
            <span
              className={'material-symbols-outlined text-4xl md:text-8xl'}
            >
              {'chevron_left'}
            </span>
          </CarouselArrow>

          <CarouselArrow
            scrollToIndex={rightIndex}
            setIndex={setIndex}
          >
            <span
              className={'material-symbols-outlined text-4xl md:text-8xl'}
            >
              {'chevron_right'}
            </span>
          </CarouselArrow>
        </CarouselArrowContainer>
      </section>
    </CarouselElementContext.Provider>
  )
}

export default Carousel
