import Avatar from './Avatar'

function AgsChat({ children }) {
  return (
    <div
      className={'ml-2 chat chat-start'}
    >
      <div
        className={'chat-image avatar'}
      >
        <Avatar
          size={8}
        />
      </div>

      <div
        className={'chat-bubble bg-message-gray text-black-brand text-xs max-w-[90%]'}
      >
        {children}
      </div>
    </div>
  )
}

export default AgsChat
