import Card from 'components/Card'
import Heading from 'components/Heading'
import RoundedIcon from 'components/RoundedIcon'

function WhyAgsListing({ listing }) {
  const listElements = listing.map(({ icon, heading, paragraph }) => (
    <Card
      className={'bg-main border-2 border-black-brand'}
      key={heading}
    >
      <Heading
        icon={(
          <RoundedIcon
            className={'text-4xl'}
          >
            {icon}
          </RoundedIcon>
    )}
        className={'font-body'}
        textClassName={'text-[1.5rem]'}
      >
        {heading}
      </Heading>

      <p
        className={'mt-3 leading-8 font-body text-lg text-justify'}
      >
        {paragraph}
      </p>
    </Card>
  ))
  return (
    <div
      className={'mt-4 grid lg:grid-cols-3 gap-4'}
    >
      {listElements}
    </div>
  )
}

export default WhyAgsListing
