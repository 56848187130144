// !mr-0 is to remove a buggy feature inherent within the daisy-ui plugin
function CarouselItem({
  id, src, alt, isImage = true, heading, subheading, paragraph,
}) {
  if (!isImage) {
    return (
      <div
        id={id}
        className={'carousel-item md:flex md:justify-center md:items-center block w-full py-2 px-4 !mr-0 bg-main box-border'}
      >
        <div>
          <h1
            className={'mt-4 md:!mt-0 underline text-center text-black-brand text-4xl md:text-8xl font-black italic'}
          >
            {heading.toUpperCase()}
          </h1>

          <div
            className={'text-center mt-2 md:!mt-8 text-3xl md:text-6xl font-bold'}
          >
            {subheading}
          </div>

          <p
            className={'text-center mt-2 md:!mt-4 text-lg md:text-2xl md:leading-[2rem] font-body whitespace-pre-line'}
          >
            {paragraph}
          </p>
        </div>
      </div>
    )
  }

  return (
    <div
      id={id}
      className={'carousel-item w-full !mr-0 box-border h-full md:h-[calc(100vh-4rem)]'}
    >
      <img
        src={src}
        className={'w-full h-full object-top object-cover'}
        alt={alt}
      />
    </div>
  )
}

export default CarouselItem
