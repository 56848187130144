import canyonCastilloImg from './img/person1.jpg'
import samuelLeeImg from './img/person2.jpg'
import mirandiBaasseriImg from './img/person3.jpg'

const testimonialData = [
  {
    id: 1,
    img: canyonCastilloImg,
    testimony: 'I\'ve personally meet great people and gained skills that helped me grow. To me, AGS is like a second home and I hope you all learn to have the same feeling.',
    name: 'Canyon Castillo',
    schoolYear: 'Fall 2020',
  },
  {
    id: 2,
    img: samuelLeeImg,
    testimony: 'Being a part of AGS gave me opportunities to learn more about my community through volunteering events, and I was able to find friends who push each other to be a better person.',
    name: 'Sameul Lee',
    schoolYear: 'Fall 2020',
  },
  {
    id: 3,
    img: mirandiBaasseriImg,
    testimony: 'AGS is like a family. Through AGS, I\'ve gained leadership experience, made great friends, and got to volunteer for some great organizations.',
    name: 'Mirandi Basseri',
    schoolYear: 'Fall 2020',
  },
]

export default testimonialData
