import { useNavigate, useLocation } from 'react-router-dom'

function NavigationBar() {
  const navigate = useNavigate()
  const location = useLocation()
  const setActive = (path) => (path === location.pathname ? 'active' : '')
  return (
    <div
      className={'btm-nav font-body z-[13] md:hidden'}
    >
      <button
        type={'button'}
        className={`bg-main ${setActive('/')}`}
        onClick={() => navigate('/')}
      >
        <span
          className={'material-symbols-outlined text-black-brand'}
        >
          {'home'}
        </span>

        <span>
          {'Home'}
        </span>
      </button>

      <button
        type={'button'}
        className={`bg-main ${setActive('/menu')}`}
        onClick={() => navigate('/menu')}
      >
        <span
          className={'material-symbols-outlined text-black-brand'}
        >
          {'menu'}
        </span>

        <span>
          {'Menu'}
        </span>
      </button>
    </div>
  )
}

export default NavigationBar
