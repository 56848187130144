function FooterColumn({ title, children }) {
  return (
    <nav>
      <h6
        className={'footer-title font-body font-bold text-lg'}
      >
        {title}
      </h6>

      {children}
    </nav>
  )
}

export default FooterColumn
