function DropDownNavItem({ title, children }) {
  return (
    <div
      className={'dropdown dropdown-end dropdown-hover z-[1]'}
    >
      <div
        role={'button'}
        className={'text-xl font-semibold'}
      >
        {title}
      </div>

      <ul
        className={'dropdown-content z-[1] menu p-2 shadow bg-amber-100 rounded-box w-80'}
      >
        {
          children
        }
      </ul>
    </div>
  )
}

export default DropDownNavItem
