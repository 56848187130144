import RoundedIcon from 'components/RoundedIcon'

function SectionIcon({ children, className }) {
  return (
    <RoundedIcon
      className={`text-5xl ${className}`}
    >
      {children}
    </RoundedIcon>
  )
}

export default SectionIcon
