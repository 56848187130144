function CarouselArrowContainer({ children }) {
  return (
    <div
      className={'absolute flex justify-between transform -translate-y-1/2 left-3 right-3 md:left-8 md:right-8 top-1/2 '}
    >
      {children}
    </div>
  )
}

export default CarouselArrowContainer
