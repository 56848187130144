import LogoImg from 'assets/ags-logo-512x512.png'

function Logo({ className }) {
  return (
    <img
      className={`${className}`}
      src={LogoImg}
      alt={'AGS Home'}
    />
  )
}

export default Logo
