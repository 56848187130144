const navData = [
  {
    id: 1,
    title: 'Services',
    menuItems: [
      {
        id: 1,
        title: 'AGS Store',
        url: 'https://agssmc.org/store/',
        icon: 'shopping_cart',
      },
      {
        id: 2,
        title: 'Newsletter',
        url: 'https://newsletter.agssmc.com/',
        icon: 'feed',
      },
      {
        id: 3,
        title: 'Hour Database',
        url: 'https://agssmc.org/database/',
        icon: 'calendar_month',
      },
      {
        id: 4,
        title: 'Events Signup',
        url: 'https://signup.smcags.org/',
        icon: 'calendar_add_on',
      },
      {
        id: 5,
        title: 'Find Independent Hours',
        url: 'https://agssmc.org/independent_hours.html',
        icon: 'hourglass',
      },
    ],
  },
  {
    id: 2,
    title: 'Information',
    menuItems: [
      {
        id: 1,
        title: 'Social Events',
        url: 'https://agssmc.org/socials.html',
        icon: 'group',
      },
      {
        id: 2,
        title: 'Committee Lists',
        url: 'https://agssmc.org/committees.html',
        icon: 'build',
      },
      {
        id: 3,
        title: 'Documents',
        url: 'https://agssmc.org/forms_and_documents.html',
        icon: 'description',
      },
    ],
  },
]

export default navData
