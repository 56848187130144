function Error() {
  return (
    <div
      className={'h-screen flex flex-col gap-8 items-center justify-center bg-main'}
    >
      <p
        className={'text-6xl md:text-9xl text-center font-bold'}
      >
        {'404 Page not found'}
      </p>

      <p
        className={'text-2xl md:text-4xl'}
      >
        {'"i think u broke it or we broke it" - Chheang'}
      </p>
    </div>
  )
}

export default Error
