import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import Navbar from 'components/Navbar'
import Footer from 'components/Footer'
import NavigationBar from 'components/BottomNavigationBar'

function MainLayout() {
  const location = useLocation()
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'instant',
    })
  }, [
    location.pathname,
  ])
  return (
    <div
      className={'bg-neutral-100 min-h-screen font-display'}
    >
      <Navbar />

      <Outlet />

      <Footer />

      <NavigationBar />
    </div>
  )
}

export default MainLayout
