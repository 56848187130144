function ProfileHeader({ children }) {
  return (
    <div
      className={'bg-main pt-[1.75rem] pb-1 px text-white-brand text-center font-body flex gap-2 items-center justify-center'}
    >
      {children}
    </div>
  )
}

export default ProfileHeader
