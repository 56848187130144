import { useContext } from 'react'
import scrollCarousel from '../utils/scrollCarousel'
import CarouselElementContext from '../context/CarouselElementContext'

function CarouselIndicator({ currentIndex, carouselItemList, setIndex }) {
  const carouselElement = useContext(CarouselElementContext)

  if (!carouselElement) return null

  return (
    <div
      className={'absolute bottom-4 flex justify-center w-full gap-3'}
    >
      {carouselItemList.map(({ id, offset = 0 }, index) => (
        <button
          type={'button'}
          className={`border-0 w-8 h-[10px] rounded-[5px] m-0 p-0${currentIndex === index ? ' bg-white-brand' : ' bg-black-brand'}`}
          onClick={() => scrollCarousel(index, setIndex, carouselElement)}
          key={id}
          aria-label={`Slide ${index + 1 + offset}`}
        />
      ))}
    </div>
  )
}

export default CarouselIndicator
