function TestimonialHeader({
  name, img, schoolYear, className,
}) {
  return (
    <div
      className={`flex gap-4 ${className} items-center`}
    >
      <div
        className={'avatar'}
      >
        <div
          className={'w-16 rounded-xl border-2 border-black-brand'}
        >
          <img
            src={img}
            alt={''}
          />
        </div>
      </div>

      <div
        className={'flex flex-column gap-2'}
      >
        <div
          className={'text-3xl font-bold'}
        >
          {name}
        </div>

        <div
          className={'text-xl opacity-75'}
        >
          {schoolYear}
        </div>
      </div>
    </div>
  )
}

export default TestimonialHeader
