function Button({
  hasOutline = false, isTransparent = false, isSquare = false, children, className, onClick,
}) {
  const btnOutline = hasOutline ? 'btn-outline border-black-brand' : ''
  const btnTransparency = isTransparent ? '' : 'btn'
  const btnSquare = isSquare ? 'btn-square' : ''
  return (
    <button
      type={'button'}
      className={`${btnTransparency} ${btnSquare} ${btnOutline} ${className}`}
      onClick={onClick}
    >
      {children}
    </button>
  )
}

export default Button
