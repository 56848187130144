function MenuItem({
  icon, href, isLast, children,
}) {
  return (
    <a
      className={`block w-full ${isLast ? '' : 'border-b border-black-main'}`}
      href={href}
    >
      <button
        type={'button'}
        className={'px-4 py-3 text-lg capitalize w-full text-left flex items-center gap-4 animation hover:bg-main'}
      >
        <span
          className={'material-symbols-outlined'}
        >
          {icon}
        </span>

        <span>
          {children}
        </span>
      </button>
    </a>
  )
}

export default MenuItem
