import TestimonialHeader from './TestiomonialHeader'
import QuoteIcon from '../assets/quote.svg'

function TestimonialCard({
  name, testimony, img, schoolYear,
}) {
  return (
    <div
      className={'grid grid-rows-[auto,1fr,auto] bg-secondary-white-brand p-4 rounded-xl shadow border-black-brand border-2'}
    >
      <div
        className={'w-10'}
      >
        <img
          src={QuoteIcon}
          alt={''}
        />
      </div>

      <p
        className={'mt-3 font-body text-xl text-justify'}
      >
        {testimony}
      </p>

      <TestimonialHeader
        className={'mt-3'}
        img={img}
        name={name}
        schoolYear={schoolYear}
      />
    </div>
  )
}

export default TestimonialCard
