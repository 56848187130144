function Phone({ children }) {
  return (
    <div
      className={'mockup-phone'}
    >
      <div
        className={'camera'}
      />

      <div
        className={'display'}
      >
        <div
          className={'artboard phone-1 bg-white-brand flex flex-col'}
        >
          {children}
        </div>
      </div>
    </div>
  )
}

export default Phone
