function Chat({ children }) {
  return (
    <div
      className={'pt-1 font-body w-full'}
    >
      {children}
    </div>
  )
}

export default Chat
