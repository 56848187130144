import navData from 'data/navData'
import MenuItem from './components/MenuItem'
import MenuSection from './components/MenuSection'

function MenuDrawer() {
  return (
    <div>
      {
        navData.map((s, sIndex) => (
          <MenuSection
            className={sIndex !== 0 ? 'mt-4' : ''}
            title={s.title}
            key={s.id}
          >
            {s.menuItems.map((i, iIndex) => (
              <MenuItem
                isLast={iIndex === s.menuItems.length - 1}
                href={i.url}
                icon={i.icon}
                key={i.id}
              >
                {i.title}
              </MenuItem>
            ))}
          </MenuSection>
        ))
      }
    </div>
  )
}

export default MenuDrawer
