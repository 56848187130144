function Heading({
  icon, color, children, className, textClassName,
}) {
  return (
    <div
      className={`flex gap-2 items-center ${className}`}
    >
      {icon}

      <h3
        className={`font-bold ${textClassName} text-${color || 'main-black'}`}
      >
        {children}
      </h3>
    </div>
  )
}

export default Heading
