function RoundedIcon({ className, children }) {
  return (
    <span
      className={`material-symbols-outlined rounded-circle ${className || 'bg-transparent text-black-brand text-5xl'}`}
    >
      {children}
    </span>
  )
}

export default RoundedIcon
